<script lang="ts">
  import 'firebaseui/dist/firebaseui.css';
  import * as R from 'ramda';
  import { useAdminStore } from '../stores/admin';
  import Admin from './Admin.svelte';

  const adminStore = useAdminStore();

  $: user = $adminStore.user;

  $: if (
    $adminStore.status == 'signedIn' &&
    (!$adminStore.user || $adminStore.user.isAnonymous)
  ) {
    adminStore.logout();
  }

  $: if ($adminStore.status == 'signedOut') {
    adminStore.login();
  }
</script>

<div class="container">
  {#if $adminStore.status === 'loading'}
    <div>Loading user...</div>
  {:else if $adminStore.status == 'error'}
    <div>There was an error loading user</div>
    <div>
      {$adminStore.error ?? new Error('Unknown error')}
    </div>
  {:else if R.isNotNil($adminStore.user)}
    <div class="header">
      <h2>You are logged in as {user.displayName ?? user.email}</h2>
      <button on:click={() => adminStore.logout()}>Logout</button>
    </div>
    <Admin />
  {/if}
</div>

<div id="firebaseui-auth-container" />

<style>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }

</style>
