<script lang="ts">
  import { Show, Track } from '@spx-utml/common';
  import { readable } from 'svelte/store';
  import { useMyVotesStore } from '../stores/vote';
  import { buildPlayUrl, formatTime } from '../utils';
  import Bar from './VoteBar.svelte';

  interface $$Props {
    show: Show;
    tracks: (Track & { value: number })[];
  }

  export let show: $$Props['show'];

  export let tracks: $$Props['tracks'];

  const currentTimeStore = readable(new Date(), (set) => {
    const interval = setInterval(() => {
      set(new Date());
    }, 1000);
    return () => clearInterval(interval);
  });

  $: nextSongTime = show.timeMarks.find(
    (timeMark) => timeMark > $currentTimeStore,
  );

  const myVotes = useMyVotesStore(show.id);

  const onVote = (
    event: CustomEvent<{ trackId: string; vote: 'yea' | 'nay' | null }>,
  ) => myVotes.vote(event.detail.trackId, event.detail.vote);

</script>

<h2>Current time: {formatTime($currentTimeStore)}</h2>

<h2>Uuden Tanssimusiikin Lista - Äänestys</h2>

<div class="bars">
  {#each tracks as track}
    <Bar
      trackId={track.id}
      time={formatTime(track.startTime)}
      title={track.title}
      value={track.value}
      myVote={$myVotes.votes?.find(({ trackId }) => trackId === track.id)
        ?.value}
      url={buildPlayUrl(show.episodeId, show.timeMarks[0], track.startTime)}
      on:vote={onVote}
    />
  {/each}
</div>
{#if nextSongTime}
  <h2>Seuraava biisi klo {formatTime(nextSongTime)}.</h2>
{/if}

<span class="uid">{$myVotes.uid}</span>

<style>
  * {
    box-sizing: border-box;
    font-family: 'DIN', sans-serif;
  }

  .bars {
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    .bars {
      width: 100%;
    }
  }

  .uid {
    color: transparent;
  }

</style>
