<script lang="ts">
  import { Show, Track } from '@spx-utml/common';
  import { readable } from 'svelte/store';
  import { buildPlayUrl, formatTime } from '../utils';
  import Bar from './ResultsBar.svelte';

  interface $$Props {
    show: Show;
    tracks: (Track & { value: number })[];
  }

  export let show: $$Props['show'];

  export let tracks: $$Props['tracks'];

  const currentTimeStore = readable(new Date(), (set) => {
    const interval = setInterval(() => {
      set(new Date());
    }, 1000);
    return () => clearInterval(interval);
  });

  $: maxNays = Math.max(...tracks.map((track) => track.nays));

  $: maxYeas = Math.max(...tracks.map((track) => track.yeas));

  $: nextSongTime = show.timeMarks.find(
    (timeMark) => timeMark > $currentTimeStore,
  );

  const openEpisode = () => window.open(buildPlayUrl(show.episodeId));
</script>

<!-- <div class="top-section">
    <h1>Watch last weeks broadcast</h1>
    <a href="https://areena.yle.fi/podcastit/ohjelmat/ylex">Watch</a>
    </div> -->

<h2>Current time: {formatTime($currentTimeStore)}</h2>

<h2>Uuden Tanssimusiikin Lista - Äänestys</h2>

<div class="bars">
  {#each tracks as track}
    <Bar
      trackId={track.id}
      time={formatTime(track.startTime)}
      title={track.title}
      value={track.value}
      yeas={track.yeas}
      nays={track.nays}
    />
  {/each}
</div>
{#if nextSongTime}
  <h2>Seuraava biisi klo {formatTime(nextSongTime)}.</h2>
{/if}

<h2>Katso Uuden Tanssimusiikin Listan viimeisin lähetys.</h2>
<br />
<img class="djOrion" src="djorion.jpeg" alt="DJOrion" on:click={openEpisode} />
<br />
<div class="links">
  <a href="https://bit.ly/djorionWA2">Liity Whatsapp-ryhmään</a>
  <a href="http://www.djorion.fi/toive">Ehdota “Kuulijan Koetus”-biisiä</a>
  <a href="https://www.facebook.com/groups/394234067703957/"
    >Liity Facebook-ryhmään</a
  >
  <a href="http://bit.ly/spotifyorion"
    >Tilaa Suomen suurin konemusiikin Spotify-lista</a
  >
</div>

<style>
  * {
    box-sizing: border-box;
    font-family: 'DIN', sans-serif;
  }

  .bars {
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    .bars {
      width: 100%;
    }
  }

  .uid {
    color: transparent;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 70%;
    margin: 0 auto;
    gap: 3em;
  }

  @media (max-width: 800px) {
    .links {
      flex-direction: column;
      gap: 1.5em;
    }
  }

  .links a {
    text-decoration: none;
    font-size: 1.5em;
    color: rgb(161, 158, 158);
    transition: all 200ms linear;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  }

  .links a:hover {
    color: white;
    transition: all 200ms linear;
    text-shadow: 0 0 4px rgba(241, 81, 81, 0.8);
  }

  .djOrion {
    cursor: pointer;
    max-width: 40%;
    height: auto;
  }

  @media (max-width: 800px) {
    .djOrion {
      max-width: 70%;
    }
  }

  h2 {
    text-align: center;
  }
</style>
