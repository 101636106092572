<script lang="ts">
  // svelte dispatch
  import { createEventDispatcher } from 'svelte';
  import { Dispatcher } from '../utils';
  import * as R from 'ramda';

  interface $$Props {
    trackId: string;
    title: string;
    time: string;
    value: number;
    myVote: 'yea' | 'nay' | null;
    url?: string;
  }

  export let trackId: $$Props['trackId'];
  export let title: $$Props['title'] = '';
  export let time: $$Props['time'];
  export let value: $$Props['value'] = 0;
  export let myVote: $$Props['myVote'] = null;
  export let url: $$Props['url'] = null;

  interface $$Events {
    vote: CustomEvent<{ trackId: string; vote: 'yea' | 'nay' | null }>;
  }

  const dispatch = createEventDispatcher<Dispatcher<$$Events>>();

  const handleVoted = (vote: 'yea' | 'nay' | null) => {
    dispatch('vote', { trackId, vote });
  };

  function onPlay() {
    window.open(url, "_blank"); // TODO: Works on mobile?
  }
</script>

<div id={trackId} class="bar">

  <div class="topRow">
    <span class="time">{time}</span>
    <span id="text">{title}</span>
    {#if R.isNotNil(url)}
    <button class="playButton" on:click={onPlay} title="Play song">▶</button>
    {/if}
  </div>

  <div class="botRow">
    {#if !myVote}
    <div class="buttons">
      <button
        class="thumbButton downvote"
        title="Vote down"
        on:click={() => handleVoted('nay')}>&#128078</button
      >
    </div>
  {:else}
    <div class="buttons">
      <button
        class="thumbButton locked downvote"
        >&#128078</button>
    </div>
  {/if}
    <div class="barGraphic">
      <div class="barSection">
        <div class="barGraphicNays" style="width: {value < 0 ? Math.abs(value) / 2 : 0}%" />
        <div class="barGraphicYeas" style="width: {value > 0 ? value / 2 : 0}%" />
      </div>
    </div>
    {#if !myVote}
    <div class="buttons">
      <button
        class="thumbButton upvote"
        title="Vote up"
        on:click={() => handleVoted('yea')}>&#128077</button
      >
    </div>
  {:else}
    <!-- <div id="check">&#9989</div> -->
    <div class="buttons">
      <button
        class="thumbButton locked upvote"
        >&#128077</button>
    </div>
  {/if}
  </div>

  <!--
  {#if !myVote}
    <div class="buttons">
      <button
        class="thumbButton"
        id="downvote"
        on:click={() => handleVoted('nay')}>&#128078</button
      >
      <button
        class="thumbButton"
        id="upvote"
        on:click={() => handleVoted('yea')}>&#128077</button
      >
    </div>
  {:else}
    <div id="check">&#9989</div>
  {/if}
  -->
</div>

<style>

* {
    box-sizing: border-box;
    font-family: 'DIN', sans-serif;
  }

  @font-face{font-family: 'DIN'; src: url('/fonts/DINCond-Medium.otf'); }

  .bar {
    z-index: 0;
    gap: 0.7em;
    display: flex;
    padding: 0.7em;
    border-radius: 0.5em;
    margin-bottom: 0.75em;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  .topRow {
    width: 100%;
    flex-grow: 1;
    display: flex;
    color: white;
    font-size: 1.4em;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-content: flex-start;
    border: 0px dotted lightgreen;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.8);
  }


  .time {
    color: white;
    display: flex;
    display: none;
    font-size: 0.7em;
    align-items: center;
    white-space: nowrap;
    padding: 0.2em 0.5em;
    border-radius: 0.3em;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  }

  #text {
    z-index: 3;
    height: 50%;
    flex-grow: 1;
    display: flex;
    min-width: 0;
    color: white;
    padding: 0 0.1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0px solid orange;
    justify-content: flex-start;
    -webkit-mask-image: linear-gradient(90deg, #000 90%, transparent 99%);
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.8);
    margin: 0 0 0.5em 0;
  }


  button {
    border: 0;
    /* position: absolute; */
    right: 0;
    /* width: 7%; */
    z-index: 10;
    color: white;
    padding: 0.1em 1.2em;
    border-radius: 0.2em;
    justify-self: flex-end;
    transition: all 0.2s ease-in-out;
    background-color: rgba(20,20,20,1);
  }

  button:hover {
    cursor: pointer;
    color: black;
    transition: all 0.2s ease-in-out;
    background-color: rgba(18,180,199 ,1.0);
  }


  .botRow {
    width: 100%;
    flex-grow: 1;
    gap: 0.5em;
    display: flex;
    color: white;
    font-size: 1.2em;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: 0px solid yellow;
    border: 0px dotted lightgreen;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.8);
  }

  .barGraphic {
    z-index: 1;
    width: 100%;
    gap: 0.5em;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }



  .barSection {
    /* top:50%; */
    width: 100%;
    height: 1.6em;
    display: flex;
    color: white;
    overflow: hidden;
    position: relative;
    border-radius: 0.2em;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(0,0,0,0.5);
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 1.0);
  }


  .barGraphicYeas,
  .barGraphicNays {
    top: 0;
    width: 0%;
    height: 100%;
    z-index: 100;
    position: absolute;
    transition: all 1s ease-in-out;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.5);
  }

  .barGraphicYeas {
    left: 50%;
    border-radius: 0 0.2em 0.2em 0;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(
      hsl(149, 100%, 39%) 40%,
      hsl(149, 71%, 29%) 61%
    );
  }

  .barGraphicNays {
    top: 0;
    right: 50%;
    border-radius: 0.2em 0 0 0.2em;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(
      hsl(352, 100%, 64%) 40%,
      hsl(352, 65%, 45%) 61%
    );

  }

  .upvote {
    background: hsl(149, 71%, 29%);
    border: 2px solid transparent;
  }

  .upvote:hover {
    background: hsl(149, 68%, 45%);
    border: 2px solid white;
  }

  .downvote {
    background: hsl(352, 100%, 65%);
    border: 2px solid transparent;
  }

  .downvote:hover {
    background: hsl(352, 100%, 68%);
    border: 2px solid white;
  }

  .locked,
  .locked:hover {
    background: rgb(0, 0, 0);
    border: 2px solid transparent;
    cursor: default;
    opacity: 0.2;
  }


  .nays,
  .yeas {
    width: 8%;
    height: 100%;
    display: flex;
    /* display: none; */
    font-size: 0.7em;
    flex-grow: 0;
    padding: 0.1em 0.5em;
    align-items: center;
    border-radius: 0.4em;
    justify-content: center;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.5);
  }

  .nays {
    background-color: rgba(53, 175, 223, 0.4);
  }

  .yeas {
    background-color:  rgba(255, 71, 96, 0.4);
  }

  .playButton {
    margin: 0 0 0.5em 0;
  }


  @media only screen and (max-width: 600px) {
    .time {
      font-size: 1em;
    }

    .barGraphic {
      width: 70%;
      font-size: 0.7em;
    }

    .results {
      justify-content: space-between;
    }
  }


  /*
  .bar {
    background-color: #737373;
    border: 0.1rem solid #37373763;
    border-radius: 0.5rem;
    padding: 0.2rem;
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    z-index: 0;
    gap: 0.5em;
  }

  .barGraphic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    background: #737373;
    border-radius: 0.4em;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 0;
  }

  .barGraphicYeas {
    width: 0%;
    z-index: 0;
    height: 100%;
    background: rgb(255, 71, 96);
    transition: all 1s ease-in-out;
    position: absolute;
    left: 51%;
  }

  .barGraphicNays {
    width: 0%;
    z-index: 0;
    height: 100%;
    background: rgb(53, 175, 223);
    transition: all 1s ease-in-out;
    position: absolute;
    right: 49%;
  }


  #text {
    gap: 0.5em;
    z-index: 3;
    height: 100%;
    display: flex;
    padding: 0.5em;
    font-size: 1.6em;
    align-items: center;
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
  }

  .time {
    display: flex;
    font-size: 1.4em;
    padding: 0.2em 0.5em;
    color: white;
    font-weight: bold;
    align-items: center;
    border-radius: 0.5em;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .buttons {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  #check {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }

  .thumbButton {
    background: rgb(255, 71, 96);
    border-radius: 0.5rem;
    border: solid white 0.1rem;
    transition: all 0.2s ease-in-out;
  }

  .thumbButton:hover {
    transform: scale(1.3) rotate(20deg);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  #downvote {
    background: rgb(53, 175, 223);
  }

  @media only screen and (max-width: 600px) {
    .time {
      font-size: 1em;
    }

    .barGraphic {
      width: 70%;
      font-size: 0.7em;
    }

    .buttons {
      justify-content: space-between;
    }
  }
  */
</style>
