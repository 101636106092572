import { Vote } from '@spx-utml/common';
import { User, getAuth, signInAnonymously } from 'firebase/auth';
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  runTransaction,
  setDoc,
  where,
} from 'firebase/firestore';
import { getContext, setContext } from 'svelte-typed-context';
import { Readable, derived, get, readable } from 'svelte/store';

const voteConverter: FirestoreDataConverter<Vote> = {
  toFirestore: function (
    modelObject: WithFieldValue<Vote>
  ): WithFieldValue<DocumentData> {
    return {
      id: modelObject.id,
      trackId: modelObject.trackId,
      uid: modelObject.uid,
      value: modelObject.value,
    };
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
    options?: SnapshotOptions | undefined
  ): Vote {
    const data = snapshot.data(options);
    return {
      id: data.id,
      trackId: data.trackId,
      uid: data.uid,
      value: data.value,
    };
  },
};

export type MyVotesStoreState = {
  status: 'pristine' | 'loading' | 'loaded' | 'error';
  uid?: string;
  votes?: Vote[];
  error?: any;
};

export type MyVotesStore = Readable<MyVotesStoreState> & {
  vote: (trackId: string, value: 'yea' | 'nay' | null) => Promise<void>;
};

const createMyVotesStore = (showId: string): MyVotesStore => {
  const userStore = readable<User | Error | null>(null, (set) => {
    try {
      const auth = getAuth();
      const unsubscribe = auth.onAuthStateChanged(
        (user) => {
          set(user);
        },
        (error) => {
          set(error);
        }
      );
      if (auth.currentUser) {
        set(auth.currentUser);
      }
      return unsubscribe;
    } catch (error) {
      set(error);
    }
  });

  const votesStore = derived<Readable<User | Error | null>, MyVotesStoreState>(
    userStore,
    (user, set) => {
      if (user instanceof Error) {
        set({
          status: 'error',
          error: user,
        });
        return;
      }
      if (!user) {
        set({
          status: 'pristine',
        });
        return;
      }
      set({
        status: 'loading',
      });
      const db = getFirestore();
      const queryRef = query(
        collection(db, 'shows', showId, 'votes'),
        where('uid', '==', user.uid)
      ).withConverter(voteConverter);
      const unsubscribe = onSnapshot(
        queryRef,
        (snapshot) => {
          const votes = snapshot.docs.map((doc) => doc.data());
          set({
            status: 'loaded',
            uid: user.uid,
            votes,
          });
        },
        (error) => {
          set({
            status: 'error',
            error,
          });
        }
      );
      return unsubscribe;
    }
  );

  return {
    subscribe: votesStore.subscribe,
    vote: async (trackId: string, value: 'yea' | 'nay' | null) => {
      let { uid } = get(votesStore);
      if (!uid) {
        try {
          const auth = getAuth();
          const { user } = await signInAnonymously(auth);
          uid = user.uid;
        } catch (error) {
          return;
        }
      }
      const db = getFirestore();
      const queryRef = query(
        collection(db, 'shows', showId, 'votes'),
        where('uid', '==', uid),
        where('trackId', '==', trackId)
      ).withConverter(voteConverter);
      const querySnapshot = await getDocs(queryRef);
      if (!querySnapshot.empty) {
        if (querySnapshot.docs[0].data().value === value) return;
        await runTransaction(db, async (transaction) => {
          transaction.update(querySnapshot.docs[0].ref, { value });
        });
      } else {
        const docRef = doc(
          collection(db, 'shows', showId, 'votes')
        ).withConverter(voteConverter);
        await setDoc(docRef, {
          id: docRef.id,
          trackId,
          uid: uid,
          value,
        });
      }
    },
  };
};

export const useMyVotesStore = (showId: string): MyVotesStore => {
  const storeKey = `myVotes-${showId}`;
  let myVotesStore = getContext<MyVotesStore>(storeKey);
  if (!myVotesStore) {
    myVotesStore = createMyVotesStore(showId);
    setContext<MyVotesStore>(storeKey, myVotesStore);
  }
  return myVotesStore;
};
