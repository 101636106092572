<script lang="ts">
  interface $$Props {
    trackId: string;
    title: string;
    time: string;
    yeas: number;
    nays: number;
    value: number;
  }

  export let trackId: $$Props['trackId'];
  export let title: $$Props['title'] = '';
  export let time: $$Props['time'];
  export let yeas: $$Props['yeas'] = 0;
  export let nays: $$Props['nays'] = 0;
  export let value: $$Props['value'] = 0;

  function timeToSeek(time: string) {
    console.log(time); // TODO: Harden this function
    time = time.replace('am', '');
    time = time.replace('pm', '');
    const [hours, minutes] = time.split(':');
    return Number(hours) * 3600 + Number(minutes) * 60;
  }

  function areenaPlay() {
    let episodeID = "1-3235351"; // TODO: Get this from "Sheets"
    let seekTime = timeToSeek(time);
    let url = "https://areena.yle.fi/" + episodeID + "?seek=" + seekTime;
    window.open(url, "_blank"); // TODO: Works on mobile?
  }


</script>

<div id={trackId} class="bar">

  <div class="topRow">
    <span class="time">{time}</span>
    <span id="text">{title}</span>
    <button on:click={ () => areenaPlay() }>▶</button>
  </div>

  <div class="botRow">
    <span class="nays">{nays}</span>
    <div class="barGraphic">
      <div class="barSection">
        <div class="barGraphicNays" style="width: {value < 0 ? Math.abs(value) / 2 : 0}%" />
        <div class="barGraphicYeas" style="width: {value > 0 ? value / 2 : 0}%" />
      </div>
    </div>
    <span class="yeas">{yeas}</span>
  </div>

</div>

<style>

  * {
    box-sizing: border-box;
    font-family: 'DIN', sans-serif;
  }

  @font-face{font-family: 'DIN'; src: url('/fonts/DINCond-Medium.otf'); }

  .bar {
    z-index: 0;
    gap: 0.7em;
    display: flex;
    padding: 0.7em;
    border-radius: 0.5em;
    margin-bottom: 0.75em;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  .topRow {
    width: 100%;
    flex-grow: 1;
    display: flex;
    color: white;
    font-size: 1.4em;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-content: flex-start;
    border: 0px dotted lightgreen;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.8);
  }


  .time {
    color: white;
    display: flex;
    display: none;
    font-size: 0.7em;
    align-items: center;
    white-space: nowrap;
    padding: 0.2em 0.5em;
    border-radius: 0.3em;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  }

  #text {
    z-index: 3;
    height: 50%;
    flex-grow: 1;
    display: flex;
    min-width: 0;
    color: white;
    padding: 0 0.1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 0px solid orange;
    justify-content: flex-start;
    -webkit-mask-image: linear-gradient(90deg, #000 90%, transparent 99%);
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.8);
  }


  button {
    border: 0;
    /* position: absolute; */
    right: 0;
    width: 7%;
    z-index: 10;
    color: white;
    padding: 0.3em 1em;
    border-radius: 0.4em;
    justify-self: flex-end;
    transition: all 0.2s ease-in-out;
    background-color: rgba(20,20,20,1);
  }

  button:hover {
    cursor: pointer;
    color: black;
    transition: all 0.2s ease-in-out;
    background-color: rgba(18,180,199 ,1.0);
  }


  .botRow {
    width: 100%;
    flex-grow: 1;
    gap: 0.5em;
    display: flex;
    color: white;
    font-size: 1.2em;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: 0px solid yellow;
    border: 0px dotted lightgreen;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.8);
  }

  .barGraphic {
    z-index: 1;
    width: 100%;
    gap: 0.5em;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }



  .barSection {
    /* top:50%; */
    width: 100%;
    height: 1.2em;
    display: flex;
    color: white;
    overflow: hidden;
    position: relative;
    border-radius: 0.2em;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgba(0,0,0,0.5);
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 1.0);
  }


  .barGraphicYeas,
  .barGraphicNays {
    top: 0;
    width: 0%;
    height: 100%;
    z-index: 100;
    position: absolute;
    transition: all 1s ease-in-out;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.5);
  }

  .barGraphicYeas {
    left: 50%;
    border-radius: 0 0.2em 0.2em 0;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(
      hsl(149, 100%, 39%) 40%,
      hsl(149, 71%, 29%) 61%
    );
  }

  .barGraphicNays {
    top: 0;
    right: 50%;
    border-radius: 0.2em 0 0 0.2em;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(
      hsl(352, 100%, 64%) 40%,
      hsl(352, 65%, 45%) 61%
    );
  }
  .nays,
  .yeas {
    width: 8%;
    height: 100%;
    display: flex;
    /* display: none; */
    font-size: 0.7em;
    flex-grow: 0;
    padding: 0.1em 0.5em;
    align-items: center;
    border-radius: 0.4em;
    justify-content: center;
    box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.5);
  }

  .nays {
    background: hsl(352, 100%, 68%);
  }

  .yeas {
    background: hsl(149, 71%, 29%);
  }

  .barGraphicYeas {
    left: 50%;
    border-radius: 0 0.2em 0.2em 0;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(
      hsl(149, 100%, 39%) 40%,
      hsl(149, 71%, 29%) 61%
    );
  }

  .barGraphicNays {
    top: 0;
    right: 50%;
    border-radius: 0.2em 0 0 0.2em;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    background-image: linear-gradient(
      hsl(352, 100%, 64%) 40%,
      hsl(352, 65%, 45%) 61%
    );

  }
  @media only screen and (max-width: 600px) {
    .time {
      font-size: 1em;
    }

    .barGraphic {
      width: 70%;
      font-size: 0.7em;
    }

    .results {
      justify-content: space-between;
    }
  }
</style>
