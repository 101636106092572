<script lang="ts">
  import Router from 'svelte-spa-router';
  import ResultsLanding from './results/Landing.svelte';
  import { useFirebaseAppStore } from './stores/firebase';
  import VoteLanding from './vote/Landing.svelte';
  import AdminLanding from './admin/Landing.svelte';

  const firebaseAppStore = useFirebaseAppStore();

  const routes = {
    '/': VoteLanding,
    '/results': ResultsLanding,
    '/admin': AdminLanding,
  };
</script>

{#if $firebaseAppStore.status == 'loading'}
  <div>Loading...</div>
{:else if $firebaseAppStore.status == 'error'}
  <div>There was an error loading the firebase app</div>
  <div>{$firebaseAppStore.error ?? 'Unknown error'}</div>
{:else}
  <Router {routes} />
{/if}

<style>

</style>
