import { FirebaseApp, initializeApp } from '@firebase/app';
import { InjectionKey, getContext, setContext } from 'svelte-typed-context';
import { Readable, readable } from 'svelte/store';

export type FirebaseAppStoreState = {
  status: 'loading' | 'initialized' | 'error';
  app?: FirebaseApp | null;
  error?: Error;
};

export type FirebaseAppStore = Readable<FirebaseAppStoreState>;

const createFirebaseAppStore = (): FirebaseAppStore => {
  const store = readable<FirebaseAppStoreState>(
    { status: 'loading' },
    (set) => {
      fetch('/__/firebase/init.json')
        .then(async (response) => {
          try {
            const options = await response.json();
            const app = initializeApp(options);
            set({ status: 'initialized', app });
          } catch (error) {
            set({ status: 'error', error });
          }
        })
        .catch((error) => set({ status: 'error', error }));
    }
  );

  return store;
};

export const firebaseAppStoreKey: InjectionKey<FirebaseAppStore> =
  Symbol('FirebaseAppStore');

export const useFirebaseAppStore = (): FirebaseAppStore => {
  let firebaseAppStore = getContext<FirebaseAppStore>(firebaseAppStoreKey);
  if (!firebaseAppStore) {
    firebaseAppStore = createFirebaseAppStore();
    setContext<FirebaseAppStore>(firebaseAppStoreKey, firebaseAppStore);
  }
  return firebaseAppStore;
};
