<script lang="ts">
  import * as R from 'ramda';
  import { useShowStore } from '../stores/show';
  import Results from './Results.svelte';

  const showStore = useShowStore();
</script>

<div class="container">
  {#if $showStore.status === 'loading'}
    <div>Liveäänestys alkaa klo 19:00</div>
  {:else if $showStore.status == 'error'}
    <div>There was an error loading show</div>
    <div>
      {$showStore.error ?? new Error('Unknown error')}
    </div>
  {:else if R.isNil($showStore.show)}
    <div>No show to vote</div>
  {:else}
    <Results show={$showStore.show} tracks={$showStore.tracks} />
  {/if}
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
