<script lang="ts">
  import { readable } from 'svelte/store';
  import { useShowStore } from '../stores/show';
  import { buildPlayUrl, formatTime } from '../utils';
  import Bar from './AdminBar.svelte';

  const showStore = useShowStore({ admin: true });

  $: show = $showStore.show;

  $: tracks = $showStore.tracks;

  const currentTimeStore = readable(new Date(), (set) => {
    const interval = setInterval(() => {
      set(new Date());
    }, 1000);
    return () => clearInterval(interval);
  });

  $: nextSongTime = show?.timeMarks?.find(
    (timeMark) => timeMark > $currentTimeStore,
  );

  const onEpisodeIdChange = (episodeId: string) =>
    showStore.updateShow(show.id, { episodeId: episodeId ?? null });
</script>

<h2>Start time: {formatTime(show?.startTime)}</h2>

<h2>Episode ID</h2>
<input
  type="text"
  class="inputEpisodeId"
  value={show?.episodeId ?? ''}
  on:change={(event) => onEpisodeIdChange(event.currentTarget.value)}
/>

<h2>Current time: {formatTime($currentTimeStore)}</h2>

<div class="bars">
  {#each tracks as track}
    <Bar
      trackId={track.id}
      time={formatTime(track.startTime)}
      title={track.title}
      yeas={track.yeas}
      nays={track.nays}
      value={track.value}
      url={buildPlayUrl(show.episodeId, show.timeMarks[0], track.startTime)}
      on:update={({ detail: update }) =>
        showStore.updateTrack(show.id, track.id, update)}
    />
  {/each}
</div>
{#if nextSongTime}
  <h2>Next song at {formatTime(nextSongTime)}.</h2>
{:else}
  <h2>No next song!</h2>
{/if}

<style>
  * {
    box-sizing: border-box;
    font-family: 'DIN', sans-serif;
  }

  @font-face {
    font-family: 'DIN';
    src: url('/fonts/DINCond-Medium.otf');
  }
  .inputEpisodeId {
    font-family: sans-serif;
    font-size: 1.0em;
    flex-grow: 1;
    border-radius: 0.1em;
  }

  .bars {
    width: 70%;
  }

  @media screen and (max-width: 600px) {
    .bars {
      width: 100%;
    }
  }
</style>
