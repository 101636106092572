import { EmailAuthProvider, User, getAuth } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { InjectionKey, getContext, setContext } from 'svelte-typed-context';
import { Readable, readable } from 'svelte/store';

export type AdminStoreState = {
  status: 'loading' | 'signedIn' | 'signedOut' | 'error';
  user?: User | null;
  error?: Error;
};

export type AdminStore = Readable<AdminStoreState> & {
  login: () => Promise<void>;
  logout: () => Promise<void>;
};

const createAdminStore = (): AdminStore => {
  const store = readable<AdminStoreState>({ status: 'loading' }, (set) => {
    try {
      const auth = getAuth();
      const unsubscribe = auth.onAuthStateChanged(
        (user) => {
          if (!user) {
            set({ status: 'signedOut', user: null });
          } else {
            set({ status: 'signedIn', user });
          }
        },
        (error) => {
          set({ status: 'error', error });
        }
      );
      if (auth.currentUser) {
        set({ status: 'signedIn', user: auth.currentUser });
      }
      return unsubscribe;
    } catch (error) {
      set(error);
    }
  });

  return {
    subscribe: store.subscribe,
    login: async () => {
      const auth = getAuth();
      const ui = new firebaseui.auth.AuthUI(getAuth());
      ui.start('#firebaseui-auth-container', {
        signInOptions: [
          {
            provider: EmailAuthProvider.PROVIDER_ID,
            signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
          },
        ],
        signInFlow: 'popup',
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            console.log('signInSuccessWithAuthResult', authResult.user);
            return false;
          },
        },
      });
    },
    logout: async () => {
      const auth = getAuth();
      await auth.signOut();
    }
  };
};

export const adminStoreKey: InjectionKey<AdminStore> = Symbol('AdminStoreKey');

export const useAdminStore = (): AdminStore => {
  let adminStore = getContext<AdminStore>(adminStoreKey);
  if (!adminStore) {
    adminStore = createAdminStore();
    setContext<AdminStore>(adminStoreKey, adminStore);
  }
  return adminStore;
};
