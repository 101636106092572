<script lang="ts">
  import * as R from 'ramda';
  import { useShowStore } from '../stores/show';
  import { buildPlayUrl, formatTime } from '../utils';
  import Vote from './Vote.svelte';

  const showStore = useShowStore();

  const openEpisode = () =>
    window.open(buildPlayUrl($showStore.show?.episodeId ?? null));
</script>

<div class="container">
  {#if $showStore.status == 'error'}
    <div>There was an error loading show</div>
    <div>
      {$showStore.error ?? new Error('Unknown error')}
    </div>
  {:else if R.isNotNil($showStore.show)}
    {#if !$showStore.show.open}
      <h2>Äänestys alkaa klo {formatTime($showStore.show.startTime)}</h2>
    {:else}
      <Vote show={$showStore.show} tracks={$showStore.tracks} />
    {/if}
  {/if}

  <h2>Katso Uuden Tanssimusiikin Listan viimeisin lähetys.</h2>
  <br />
  <img
    class="djOrion"
    src="djorion.jpeg"
    alt="DJOrion"
    on:click={openEpisode}
  />
  <br />
  <div class="links">
    <a href="https://bit.ly/djorionWA2">Liity Whatsapp-ryhmään</a>
    <a href="http://www.djorion.fi/toive">Ehdota “Kuulijan Koetus”-biisiä</a>
    <a href="https://www.facebook.com/groups/394234067703957/"
      >Liity Facebook-ryhmään</a
    >
    <a href="http://bit.ly/spotifyorion"
      >Tilaa Suomen suurin konemusiikin Spotify-lista</a
    >
  </div>
</div>

<style>
  * {
    box-sizing: border-box;
    font-family: 'DIN', sans-serif;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 70%;
    margin: 0 auto;
    gap: 3em;
  }

  @media (max-width: 800px) {
    .links {
      flex-direction: column;
      gap: 1.5em;
    }
  }

  .links a {
    text-decoration: none;
    font-size: 1.5em;
    color: rgb(161, 158, 158);
    transition: all 200ms linear;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  }

  .links a:hover {
    color: white;
    transition: all 200ms linear;
    text-shadow: 0 0 4px rgba(241, 81, 81, 0.8);
  }

  .djOrion {
    cursor: pointer;
    max-width: 40%;
    height: auto;
  }

  @media (max-width: 800px) {
    .djOrion {
      max-width: 70%;
    }
  }

  h2 {
    text-align: center;
  }
</style>
