import { Show, Track } from '@spx-utml/common';
import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
  WithFieldValue,
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  limit
} from 'firebase/firestore';
import * as R from 'ramda';
import { InjectionKey, getContext, setContext } from 'svelte-typed-context';
import { Readable, derived, readable } from 'svelte/store';
import { isError } from '../utils';

export const showConverter: FirestoreDataConverter<Show> = {
  toFirestore: function (
    modelObject: WithFieldValue<Show>
  ): WithFieldValue<DocumentData> {
    return R.reject(R.isNil, {
      id: modelObject.id,
      name: modelObject.name,
      episodeId: modelObject.episodeId,
      startTime: Timestamp.fromDate(modelObject.startTime as Date),
      endTime: modelObject.endTime
        ? Timestamp.fromDate(modelObject.endTime as Date)
        : null,
      open: modelObject.open ?? false,
      timeMarks: modelObject.timeMarks
        ? (modelObject.timeMarks as Date[]).map((timeMark) =>
            Timestamp.fromDate(timeMark)
          )
        : [],
      createdAt: modelObject.createdAt
        ? Timestamp.fromDate(modelObject.createdAt as Date)
        : Timestamp.fromDate(modelObject.startTime as Date),
      updatedAt: Timestamp.now(),
    });
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
    options?: SnapshotOptions | undefined
  ): Show {
    const data = snapshot.data(options);
    return R.reject(R.isNil, {
      id: data.id,
      name: data.name,
      episodeId: data.episodeId,
      startTime: data.startTime?.toDate(),
      endTime: data.endTime?.toDate(),
      open: data.open ?? false,
      timeMarks: data.timeMarks?.map((timeMark: Timestamp) =>
        timeMark.toDate()
      ),
      createdAt: data.createdAt?.toDate() ?? data.startTime?.toDate(),
      updatedAt: data.updatedAt?.toDate(),
    }) as Show;
  },
};

export const trackConverter: FirestoreDataConverter<Track> = {
  toFirestore: function (
    modelObject: WithFieldValue<Track>
  ): WithFieldValue<DocumentData> {
    return R.reject(R.isNil, {
      id: modelObject.id,
      position: modelObject.position,
      startTime: Timestamp.fromDate(modelObject.startTime as Date),
      title: modelObject.title,
      yeas: modelObject.yeas,
      nays: modelObject.nays,
      open: modelObject.open,
      taskId: modelObject.taskId,
    });
  },
  fromFirestore: function (
    snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
    options?: SnapshotOptions | undefined
  ): Track {
    const data = snapshot.data(options);
    return R.reject(R.isNil, {
      id: data.id,
      position: data.position,
      startTime: data.startTime?.toDate(),
      title: data.title,
      yeas: data.yeas,
      nays: data.nays,
      open: data.open,
      taskId: data.taskId,
    }) as Track;
  },
};

export type ShowStoreState = {
  status: 'loading' | 'loaded' | 'error';
  show: Show | null;
  tracks: (Track & { value: number })[];
  error?: any;
};

export type ShowStore = Readable<ShowStoreState> & {
  updateShow: (showId: string, update: Partial<Show>) => Promise<void>;
  updateTrack: (
    showId: string,
    trackId: string,
    update: Partial<Track>
  ) => Promise<void>;
};

const createShowStore = (admin: boolean): ShowStore => {
  const showStore = readable<Show | Error | null>(null, (set) => {
    try {
      const db = getFirestore();
      const showsQuery = query(
        collection(db, 'shows'),
        orderBy('startTime', 'desc'),
        limit(1)
      ).withConverter(showConverter);
      const unsubscribe = onSnapshot(
        showsQuery,
        (snapshot) => {
          if (snapshot.empty) {
            set(null);
          } else {
            set(snapshot.docs[0].data());
          }
        },
        (error) => {
          set(error);
        }
      );
      return unsubscribe;
    } catch (error) {
      set(error);
    }
  });

  const tracksStore = derived<
    typeof showStore,
    (Track & { value: number })[] | Error
  >(showStore, (show, set) => {
    if (!show || isError(show) || (!show.open && !admin)) {
      set([]);
    } else {
      try {
        const db = getFirestore();
        const unsubscribe = onSnapshot(
          admin
            ? query(
                collection(db, 'shows', show.id, 'tracks'),
                orderBy('position')
              ).withConverter(trackConverter)
            : query(
                collection(db, 'shows', show.id, 'tracks'),
                where('open', '==', true),
                orderBy('position')
              ).withConverter(trackConverter),
          (snapshot) => {
            const tracks = snapshot.docs
              .map((doc) => doc.data())
              .map((track) => ({ ...track, value: track.yeas - track.nays }));
            const inMin = Math.min(
              ...tracks
                .filter(({ value }) => value < 0)
                .map(({ value }) => value)
            );
            const inMax = Math.max(
              ...tracks
                .filter(({ value }) => value > 0)
                .map(({ value }) => value)
            );
            const normalize = (value: number): number => {
              if (value === 0) {
                return 0;
              }
              if (value > 0) {
                return (value * 100) / inMax;
              }
              return -((value * 100) / inMin);
            };
            set(
              tracks.map((track) => ({
                ...track,
                value: normalize(track.value),
              }))
            );
          },
          (error) => {
            set(error);
          }
        );
        return unsubscribe;
      } catch (error) {
        set(error);
      }
    }
  });

  const store = derived<[typeof showStore, typeof tracksStore], ShowStoreState>(
    [showStore, tracksStore],
    ([show, tracks], set) => {
      if (!show) {
        set({
          status: 'loading',
          show: null,
          tracks: [],
        });
        return;
      }
      if (isError(show) || isError(tracks)) {
        set({
          status: 'error',
          show: null,
          tracks: [],
          error: isError(show) ? show : isError(tracks) ? tracks : undefined,
        });
      } else {
        set({
          status: 'loaded',
          show,
          tracks,
        });
      }
    }
  );

  return {
    ...store,
    async updateShow(showId: string, update: Partial<Show>) {
      const db = getFirestore();
      const showRef = doc(db, 'shows', showId).withConverter(showConverter);
      const show = await getDoc(showRef);
      if (!show.exists()) {
        throw new Error('Show does not exist');
      }
      updateDoc(showRef, update);
    },
    async updateTrack(showId: string, trackId: string, update: Partial<Track>) {
      const db = getFirestore();
      const trackRef = doc(
        db,
        'shows',
        showId,
        'tracks',
        trackId
      ).withConverter(trackConverter);
      const track = await getDoc(trackRef);
      await updateDoc(trackRef, {
        title: update.title ?? track.data().title,
        yeas: update.yeas ?? track.data().yeas,
        nays: update.nays ?? track.data().nays,
      });
    },
  };
};

export const showStoreKey: InjectionKey<ShowStore> = Symbol('ShowStore');

export const useShowStore = (arg?: { admin?: boolean }): ShowStore => {
  let showStore = getContext<ShowStore>(showStoreKey);
  if (!showStore) {
    showStore = createShowStore(arg?.admin ?? false);
    setContext<ShowStore>(showStoreKey, showStore);
  }
  return showStore;
};
